<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container-officeSolution">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>中小微企业数字化办公解决方案</h2>

          <div class="tit">
            帮助企业实现智能、高效的办公管理，提高工作效率和业务水平，
          </div>
          <div class="tit">同时也可以降低成本，为企业发展提供有力支持。</div>
          <div class="exp" @click="handleTest">立即体验</div>
        </div>
      </div>

      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="text-align: left">
          <h4>中小微企业数字化办公解决方案</h4>
          <div class="tit">帮助企业实现智能化、高效化的办公管理，</div>
          <div class="tit">提高工作效率和业务水平，</div>
          <div class="tit">
            同时也可以降低成本、减少风险，为企业的发展提供有力支持。
          </div>
          <!-- <span class="exp" @click="handleTest">立即体验</span> -->
        </div>
      </div>

      <div class="pc-pro-power d-none d-lg-block">
        <h4 class="pro-tit">产品能力</h4>
        <div class="container text-center">
          <div class="tabs">
            <div
              :class="['items', idx == index ? 'active' : '']"
              v-for="(item, index) in tabList"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="idx == index ? item.img2 : item.img1" />
              <div>{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="row" v-show="idx == 0">
              <div class="col-xs-12 col-md-6 lf">
                <!-- <h6>数字化办公平台——伟班</h6> -->
                <p style="text-align: left">
                  伟班让企业管理及业务双在线，
                  进而提高工作效率：支持用户随时随地处理工作事务，避免了传统办公中因时间和空间限制而导致的效率低下问题；
                  提高灵活性：可根据自身需求和时间安排工作，提高了工作的灵活性和自由度；
                  降低成本：减少因出差、会议等产生的费用，从而降低企业运营成本；
                  提高协作效率：通过在线沟通和协作工具，提高团队之间的协作效率和质量；
                  提高安全性：通过安全加密技术和云端存储等方式，保障企业数据的安全性和隐私性。
                </p>
                <div style="text-align: left">
                  <button
                    @click="handleTest"
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    立即体验
                  </button>
                </div>
              </div>
              <div
                class="col-xs-12 col-md-6"
                style="text-align: center; padding: 10px"
              >
                <img
                  src="@/assets/newSite/officeSolution/p1.png"
                  alt=""
                  style="width: 64%"
                />
              </div>
            </div>
            <div class="row" v-show="idx == 1">
              <div class="col-xs-12 col-md-6 lf">
                <!-- <h6>数字化办公平台——伟班</h6> -->
                <p style="text-align: left">
                  可以实现会议全流程数字化管理，通过会议发言评分、工作计划制定及执行跟踪等特色功能，帮助企业提高会议效率，降低会议成本，提高会议价值
                </p>
                <div style="text-align: left">
                  <button
                    @click="handleTest"
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    立即体验
                  </button>
                </div>
              </div>
              <div
                class="col-xs-12 col-md-6"
                style="text-align: center; padding: 10px"
              >
                <img
                  src="@/assets/newSite/officeSolution/p2.png"
                  alt=""
                  style="width: 64%"
                />
              </div>
            </div>
            <div class="row" v-show="idx == 2">
              <div class="col-xs-12 col-md-6 lf">
                <!-- <h6>数字化办公平台——伟班</h6> -->
                <p style="text-align: left">
                  支持多人在线实时沟通、文件共享和任务分配等功能。能够帮助企业提高沟通效率，降低沟通成本，提升团队协作能力。拥有简单易用的用户界面和强大的数据安全保障，为企业提供安全、便捷的沟通协作体验
                </p>
                <div style="text-align: left">
                  <button
                    @click="handleTest"
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    立即体验
                  </button>
                </div>
              </div>
              <div
                class="col-xs-12 col-md-6"
                style="text-align: center; padding: 10px"
              >
                <img
                  src="@/assets/newSite/officeSolution/p3.png"
                  alt=""
                  style="width: 64%"
                />
              </div>
            </div>
            <div class="row" v-show="idx == 3">
              <div class="col-xs-12 col-md-6 lf">
                <!-- <h6>数字化办公平台——伟班</h6> -->
                <p style="text-align: left">
                  支持企业按场景需求做系统集成，实现数据互通和业务协同。企业可以快速构建一体化的数字化办公环境，提高工作效率和管理水平
                </p>
                <div style="text-align: left">
                  <button
                    @click="handleTest"
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    立即体验
                  </button>
                </div>
              </div>
              <div
                class="col-xs-12 col-md-6"
                style="text-align: center; padding: 10px"
              >
                <img
                  src="@/assets/newSite/officeSolution/p4.png"
                  alt=""
                  style="width: 64%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-pro-power d-block d-lg-none">
        <h4 class="pro-tit">产品能力</h4>
        <div class="container text-center">
          <div class="tabs">
            <div
              :class="['items', idx == index ? 'active' : '']"
              v-for="(item, index) in tabList"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="idx == index ? item.img2 : item.img1" />
              <div>{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="row" v-show="idx == 0">
              <div class="col-xs-12 col-md-6 lf">
                <!-- <h6>数字化办公平台——伟班</h6> -->
                <p>
                  伟班APP-场景的移动办公应用，让业务、管理双在线更高效
                  提高工作效率:随时随地处理工作事务，避免了传统办公中因时间和空间限制而导致的效率低下问题
                  提高灵活性:根据自己的需求和时间安排工作，提高了工作的灵活性和自由度
                  降低成本:减少因出差、会议等产生的费用，从而降低企业的运营成本
                  提高协作效率:通过在线沟通和协作工具，提高团队之间的协作效率和质量
                  提高安全性:通过安全加密技术和云端存储等方式，保障企业数据的安全性和隐私性。
                </p>
                <div>
                  <!-- <button type="button" class="btn btn-primary btn-sm">
                    立即体验
                  </button> -->
                </div>
              </div>
              <div
                class="col-xs-12 col-md-6"
                style="text-align: center; padding: 10px"
              >
                <img
                  src="@/assets/newSite/officeSolution/p1.png"
                  alt=""
                  style="width: 100%"
                />
              </div>
            </div>
            <div class="row" v-show="idx == 1">
              <div class="col-xs-12 col-md-6 lf">
                <!-- <h6>数字化办公平台——伟班</h6> -->
                <p>
                  可以实现会议全流程数字化管理，通过会议发言评分、工作计划制定及执行跟踪等特色功能，帮助企业提高会议效率，降低会议成本，提高会议价值
                </p>
                <div>
                  <!-- <button type="button" class="btn btn-primary btn-sm">
                    立即体验
                  </button> -->
                </div>
              </div>
              <div
                class="col-xs-12 col-md-6"
                style="text-align: center; padding: 10px"
              >
                <img
                  src="@/assets/newSite/officeSolution/p2.png"
                  alt=""
                  style="width: 100%"
                />
              </div>
            </div>
            <div class="row" v-show="idx == 2">
              <div class="col-xs-12 col-md-6 lf">
                <!-- <h6>数字化办公平台——伟班</h6> -->
                <p>
                  支持多人在线实时沟通、文件共享和任务分配等功能。能够帮助企业提高沟通效率，降低沟通成本，提升团队协作能力。拥有简单易用的用户界面和强大的数据安全保障，为企业提供安全、便捷的沟通协作体验
                </p>
                <div>
                  <!-- <button type="button" class="btn btn-primary btn-sm">
                    立即体验
                  </button> -->
                </div>
              </div>
              <div
                class="col-xs-12 col-md-6"
                style="text-align: center; padding: 10px"
              >
                <img
                  src="@/assets/newSite/officeSolution/p3.png"
                  alt=""
                  style="width: 100%"
                />
              </div>
            </div>
            <div class="row" v-show="idx == 3">
              <div class="col-xs-12 col-md-6 lf">
                <!-- <h6>数字化办公平台——伟班</h6> -->
                <p>
                  支持企业按场景需求做系统集成，实现数据互通和业务协同。企业可以快速构建一体化的数字化办公环境，提高工作效率和管理水平
                </p>
                <div>
                  <!-- <button type="button" class="btn btn-primary btn-sm">
                    立即体验
                  </button> -->
                </div>
              </div>
              <div
                class="col-xs-12 col-md-6"
                style="text-align: center; padding: 10px"
              >
                <img
                  src="@/assets/newSite/officeSolution/p4.png"
                  alt=""
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="more-function">
        <h4 class="pro-tit">众多功能应用，为企业的发展提供有力支持</h4>
        <h6 class="pro-tits">
          帮助企业实现智能化、高效化的生产管理，提高工作效率和业务水平，同时也可以降低成本、减少风险
        </h6>
        <div class="container text-center">
          <div class="row row-cols-4 row-cols-lg-6 g-2 g-lg-3">
            <div class="col" v-for="(item, index) in funList" :key="index">
              <div class="p-3">
                <img :src="item.img" alt="" />
                <h6>{{ item.tit }}</h6>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <div class="scene">
        <h4 class="pro-tit">赋能多角色办公</h4>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/officeSolution/a1.png" alt="" />
                <h6>高层领导</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/officeSolution/a2.png" alt="" />
                <h6>中层干部</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/officeSolution/a3.png" alt="" />
                <h6>普通职工</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/officeSolution/a4.png" alt="" />
                <h6>入职新人</h6>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <div class="cooperation d-none d-lg-block">
        <h4 class="pro-tit">合作客户</h4>
        <div class="wrap container">
          <div class="items" v-for="(item, index) in funList2" :key="index" @mouseenter="enter(index)" @mouseleave="leave()">
            <div class="p-3" style="height: 180px;">
              <img :src="item.img" alt="" />
            </div>
            <div
              class="des"
              style="height: 180px;"
              v-if="ids == index" 
            >
              <h4>{{ item.tit }}</h4>
              <div style="font-size: 12px">{{ item.des }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-cooperation d-block d-lg-none">
        <h4 class="pro-tit">合作客户</h4>
        <div class="wrap">
          <div class="items" v-for="(item, index) in funList2" :key="index">
            <div class="p-3">
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>

      <right-nav-new class="d-none d-lg-block" />
    </div>

    <NewFooter />
    <MobileFooter class="d-block d-lg-none" />
  </div>
</template>
      
      <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);

export default {
  name: "hardware",
  data() {
    return {
      activeName: "1",
      idx: 0,
      ids: -1,
      tabList: [
        {
          img1: require("@/assets/newSite/officeSolution/s1.png"),
          img2: require("@/assets/newSite/officeSolution/s1-1.png"),
          tit: "数字化办公",
        },
        {
          img1: require("@/assets/newSite/officeSolution/s2.png"),
          img2: require("@/assets/newSite/officeSolution/s2-2.png"),
          tit: "伟班会议系统",
        },
        {
          img1: require("@/assets/newSite/officeSolution/s3.png"),
          img2: require("@/assets/newSite/officeSolution/s3-3.png"),
          tit: "数字化沟通协作",
        },
        {
          img1: require("@/assets/newSite/officeSolution/s4.png"),
          img2: require("@/assets/newSite/officeSolution/s4-4.png"),
          tit: "系统集成",
        },
      ],
      funList: [
        {
          tit: "项目管理",
          img: require("@/assets/newSite/solution/f1.png"),
        },
        {
          tit: "人力资源",
          img: require("@/assets/newSite/solution/f2.png"),
        },
        {
          tit: "财务管理",
          img: require("@/assets/newSite/solution/f3.png"),
        },
        {
          tit: "行政审批",
          img: require("@/assets/newSite/solution/f4.png"),
        },
        {
          tit: "会议管理",
          img: require("@/assets/newSite/solution/f5.png"),
        },
        {
          tit: "档案管理",
          img: require("@/assets/newSite/solution/f6.png"),
        },
        {
          tit: "智能考勤",
          img: require("@/assets/newSite/solution/f7.png"),
        },
        {
          tit: "HR管理",
          img: require("@/assets/newSite/solution/f8.png"),
        },
        {
          tit: "BI报表中心",
          img: require("@/assets/newSite/solution/f9.png"),
        },
        {
          tit: "公共资料库",
          img: require("@/assets/newSite/solution/f10.png"),
        },
        {
          tit: "社保管理",
          img: require("@/assets/newSite/solution/f11.png"),
        },
        {
          tit: "工资条",
          img: require("@/assets/newSite/solution/f12.png"),
        },
        {
          tit: "知识库",
          img: require("@/assets/newSite/solution/f13.png"),
        },
        {
          tit: "通知公告",
          img: require("@/assets/newSite/solution/f14.png"),
        },
        {
          tit: "合同",
          img: require("@/assets/newSite/solution/f15.png"),
        },
        {
          tit: "出差",
          img: require("@/assets/newSite/solution/f16.png"),
        },
        {
          tit: "用印",
          img: require("@/assets/newSite/solution/f17.png"),
        },
        {
          tit: "报销",
          img: require("@/assets/newSite/solution/f18.png"),
        },
        {
          tit: "工作日报",
          img: require("@/assets/newSite/solution/f19.png"),
        },
        {
          tit: "工作汇报",
          img: require("@/assets/newSite/solution/f20.png"),
        },
        {
          tit: "绩效管理",
          img: require("@/assets/newSite/solution/f244.png"),
        },
        {
          tit: "薪酬管理",
          img: require("@/assets/newSite/solution/f22.png"),
        },
        {
          tit: "请示函",
          img: require("@/assets/newSite/solution/f23.png"),
        },

        {
          tit: "更多",
          img: require("@/assets/newSite/officeSolution/more.png"),
        },
      ],

      funList2: [
        {
          tit: "华瀚控股",
          img: require("@/assets/newSite/solution/c1.png"),
          des: "深圳市华瀚科技控股有限公司是在2000年第二届“高交会”上签约成立，现已发展成为以智慧管网为特征的管道系统解决方案服务商。公司总部位于深圳市高新技术产业园区、深圳生产基地在坪山新区，在成都、长沙、武汉、昆明等地均有产业基地。优制云根据其需求为其定制全套数字化方案。",
        },
        {
          tit: "锦瀚智慧管网",
          img: require("@/assets/newSite/solution/c2.png"),
          des: "锦瀚智慧管网技术有限公司成立于2013年，注册资本5000万，公司位于深圳市高新技 术产业园北区。主要围绕智慧城市地下透明空间，研究、设计、开发、生产智慧管网(管廊)、智 能管材、智能传感及管网周边智能化硬件等软硬件系列产品，致力于城市地下透明空间产业 的发展。优制云根据其需求为其定制全套数字化方案。",
        },
        {
          tit: "优管网",
          img: require("@/assets/newSite/you.png"),
          des: "优管网致力于服务中小实体企业,以管道产品及其设备、塑料大宗商品、智能制造应用技术三个专业领域为特色,构建工业互联网交易平台,让商家快速触达海量用户,让买家快速找到优质货源。优制云根据其需求为其定制全套数字化方案。",
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },

  methods: {
    handleTest() {
      window.open("https://apipecloud.veiban.com/login");
    },
    handleTab(index) {
      this.idx = index;
    },
    enter(index) {
      this.ids = index;
    },
    leave() {
      this.ids = -1;
    },
  },
};
</script>
      
<style lang="less"  scoped>
.apipe-cloud-course-container-officeSolution {
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/officeSolution/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
      .exp {
        margin-top: 2rem;
        font-size: 14px;
        border: 1px solid white;
        display: inline-block;
        width: 100px;
        padding: 2px 0;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/officeSolution/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .pc-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .tabs {
      display: flex;
      .items {
        font-size: 14px;
        flex: 25%;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        &:hover {
          cursor: pointer;
        }
        img {
          width: 60px;
          height: 60px;
        }
      }
      .active {
        background: rgba(20, 132, 255, 1);
        color: white;
      }
    }
    .tab-cot {
      //   border: 1px solid red;

      padding: 20px;
      .row {
        min-height: 300px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        // text-align: left;
        padding: 20px;
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 100px;
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }

  .mobile-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .tabs {
      display: flex;
      .items {
        font-size: 14px;
        flex: 25%;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        &:hover {
          cursor: pointer;
        }
        img {
          width: 60px;
          height: 60px;
        }
      }
      .active {
        background: rgba(20, 132, 255, 1);
        color: white;
      }
    }
    .tab-cot {
      //   border: 1px solid red;

      padding: 20px;
      .row {
        min-height: 300px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        // text-align: left;
        padding: 10px;
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 100px;
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }

  .more-function {
    margin: 50px 0;
    .pro-tits {
      color: #666;
      margin-bottom: 30px;
      padding: 0 10px;
    }
    .col {
      .p-3 {
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        padding: 24px 0 10px 0 !important;
      }
    }

    img {
      width: 40px;
      height: 40px;
    }
    p {
      color: #888;
      font-size: 13px;
    }
    h6 {
      margin-top: 1rem;
    }
  }

  .scene {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100%;
      height: 200px;
    }
    h6 {
      margin-top: 1rem;
    }
  }

  .cooperation {
    margin-bottom: 30px;
    .pro-tit {
      margin-bottom: 30px;
    }
    .wrap {
      display: flex;
      justify-content: center;
      .items {
        margin: 0 20px;
        flex: 1;
        position: relative;
        .p-3 {
          height: 180px;
          // width: 280px !important;
          // border: 1px solid red;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          img {
            width: 50%;
          }
        }

        .des {
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(20, 132, 255, 1);
          color: white;
          padding: 20px;
        }
      }
    }
  }

  .mobile-cooperation {
    margin-bottom: 30px;
    .pro-tit {
      margin-bottom: 30px;
    }
    .wrap {
      .items {
        display: flex;
        //  flex-direction: column;
        justify-content: center;
        margin: 20px 0;
        .p-3 {
          height: 150px;
          width: 280px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          img {
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
      